.hero-icons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 30px;
}

.tabs {
  font-family: arial;
  margin: 0 auto;
  text-align: center;
}

.tab-buttons {
  margin-bottom: 50px;
  button {
    &:not(:last-child) {
      margin-right: 10px;
    }
    &.active {
      background: transparent;
      color: #f9004d;
      box-shadow: 0 10px 15px 0 rgb(0, 0, 0 / 10%);
      transform: translateY(-5px);
    }
  }
}

.button {
  margin-right: 15px;
  font-size: 100px;
  background: transparent;
  border: none;
  outline: none;
  padding: 10px 20px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.4);
  transition: all ease-in-out 0.2s;
  border-bottom: 2px solid transparent;
}
.button-size {
  font-size: 50px;
}
.active {
  border-bottom: 2px solid #42b3f4;
  font-size: 50px;
  color: rgb(252, 252, 252);
}
